.illust {  
  margin: 0 auto;
  height: 15rem;
  object-fit: contain;
}

@media (max-width: 35rem) {
  .illust {
    height: 10rem;
  }
}

.questionBox {
  text-align: center;  
}

.questionSub{
  border: 3px dashed rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}

.editQuestion{
  width: 100%;  
  margin-bottom: 1rem;
}