@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap');

html {
  height: 100%;
  font-size: 16px;
  font-family: 'Do Hyeon', sans-serif;
  --primary-color: #FBCE7B;
  --accent-color: #8D5AC4;
  --shadow-color: #FBFADD;
  --text-color: #444;
  --correct-color: #4caf50;
  --error-color: #d32f2f;
  --box-shadow: 1px 1px 2px #FBCE7B,
    -1px -1px 2px #FBCE7B;
  --border-radius: 1.2rem;
}

body {
  margin: 0;
  background-color: var(--shadow-color);
}

#root {
  width: 100%;  
}

.MuiInput-root,
.MuiInputBase-root,
.MuiInputLabel-root,
.MuiTypography-root,
.react-horizontal-scrolling-menu--item,
.MuiChip-root,
.MuiButton-root {
  font-family: inherit;
}

.MuiButton-root,
.MuiInputLabel-root.Mui-focused{
  color: inherit;
}

.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: var(--primary-color);
}

.MuiInputLabel-root.Mui-error {
  color: var(--error-color);
}

.MuiButton-root {
  text-transform: inherit;
}

.MuiInput-root:after {
  border-bottom: 2px solid var(--text-color);
}

.MuiCheckbox-root.Mui-checked {
  color: var(--primary-color);
}

.MuiSwitch-switchBase.Mui-checked{
  color: var(--shadow-color);
}

.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
  background-color: var(--shadow-color);
}

.MuiPaper-root,
.MuiListSubheader-root {
  background-color: inherit;
}

.MuiListSubheader-root {
  box-shadow: var(--box-shadow);
  background-color: var(--shadow-color);
}