.categoryTitle {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.chapterPaper {
  padding: 1.5rem;
}

.paperHistory {
  background-color: var(--primary-color);
}