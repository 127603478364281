.bottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  color: var(--primary-color);
  background-color: var(--shadow-color);
  box-shadow: var(--box-shadow);
  z-index: 1;
}