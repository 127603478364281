.MuiStepIcon-root.Mui-active {
  color: var(--shadow-color);
}

.MuiStepIcon-root.Mui-active text {
  fill: rgba(0, 0, 0, 0.38);
}

.MuiStepConnector-line {
  border-color: var(--shadow-color);
}