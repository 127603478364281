.rightSideButtonContainer {
  margin-left: auto;  
}

.authButton {
  padding: 0 0.7rem;
}

.modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 1rem 0.5rem 56px;  
  border-radius: var(--border-radius);  
  background-color: var(--shadow-color);
  box-shadow: 0px -4px 0px rgba(0, 0, 0, 0.2);
}

@media (max-width: 35rem){
  .modal{
    width: 60%;
  }
}