.searchBarContainer {
  width: 40%;
  margin: 0 auto;
  background-color: var(--primary-color);
  text-align: center;
  margin-bottom: 1rem;
}

.searchBar {
  width: 100%;
  border: 3px solid var(--primary-color);
  background-color: var(--shadow-color);
}

@media (max-width: 35rem) {
  .searchBarContainer {
    width: 90%;
  }
}

.searchButton {
  margin-left: 0;
}