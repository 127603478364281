.pageContainer {
  height: inherit;
  background-color: var(--primary-color);
}

.headerContainer{
  display: flex;  
  align-content: flex-start;
  padding-top: 1rem;
}

.bodyContainer {
  height: inherit;
  padding: 0.5rem 0.5rem 56px;  
  margin-top: 3rem;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  background-color: var(--shadow-color);
  box-shadow: 0px -4px 0px rgba(0, 0, 0, 0.2);  
}

@media (max-width: 35rem) {
  .bodyContainer {
    margin-top: 1rem;
  }
}