.flexDirectionColumn{
  flex-direction: column;
}

.editModeContainer{
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formContainer {
  width: 60%;
  border: 3px solid var(--primary-color);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  padding: 0.5rem;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.gridItem {
  background-color: inherit;
  box-shadow: var(--box-shadow);
}

.rightSideButtonContainer {  
  margin-left: auto;    
}